import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './index.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import eventBus from './utils/EventBus';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import store from './store';
import VueCookies from 'vue-cookies';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser,
    faArrowLeft,
    faUserPlus,
    faUserMinus,
    faPlus,
    faEye,
    faEyeSlash,
    faUsers,
    faChildren,
    faUserCheck,
    faUserSlash,
    faMobileScreen,
    faFolderPlus,
    faCircleXmark,
    faCloudArrowUp,
    faChevronRight,
    faTrashCan,
    faRightFromBracket,
    faUserPen,
    faUserXmark,
    faAddressBook,
    faEnvelopeCircleCheck,
    faCalendarCheck,
    faSliders,
    faUsersGear,
    faChartColumn,
    faMinus,
    faTag,
    faCommentSms,
    faEnvelope,
    faCoins,
    faPaperPlane,
    faLayerGroup,
    faHouseChimneyUser,
    faArrowDownUpLock,
    faKey,
    faArrowDownUpAcrossLine,
    faFingerprint,
    faChartPie,
    faCopy,
    faLeaf,
    faBed,
    faWandMagicSparkles,
    faMapLocationDot,
    faCandyCane,
    faICursor,
    faPalette,
    faEdit,
    faTextHeight,
    faClone,
    faFont,
    faImage,
    faHourglassHalf,
    faXmark,
    faUpRightAndDownLeftFromCenter,
    faArrowDown19,
    faHeart,
    faPenToSquare,
    faList,
    faListCheck,
    faCircleCheck,
    faSheetPlastic,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faUser,
    faArrowLeft,
    faUserPlus,
    faUserMinus,
    faPlus,
    faMinus,
    faEye,
    faEyeSlash,
    faUsers,
    faChildren,
    faUserCheck,
    faUserSlash,
    faMobileScreen,
    faFolderPlus,
    faCircleXmark,
    faCloudArrowUp,
    faChevronRight,
    faTrashCan,
    faRightFromBracket,
    faUserPen,
    faUserXmark,
    faAddressBook,
    faEnvelopeCircleCheck,
    faCalendarCheck,
    faSliders,
    faUsersGear,
    faChartColumn,
    faTag,
    faCommentSms,
    faEnvelope,
    faCoins,
    faPaperPlane,
    faLayerGroup,
    faHouseChimneyUser,
    faArrowDownUpLock,
    faKey,
    faArrowDownUpAcrossLine,
    faFingerprint,
    faChartPie,
    faCopy,
    faLeaf,
    faBed,
    faWandMagicSparkles,
    faMapLocationDot,
    faCandyCane,
    faICursor,
    faPalette,
    faEdit,
    faTextHeight,
    faClone,
    faFont,
    faImage,
    faHourglassHalf,
    faXmark,
    faUpRightAndDownLeftFromCenter,
    faArrowDown19,
    faHeart,
    faPenToSquare,
    faList,
    faListCheck,
    faCircleCheck,
    faSheetPlastic
);

// Create the Vue app
const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('VueDatePicker', VueDatePicker);

// Use the router
app.provide('eventBus', eventBus);

app.use(router);
app.use(store);
app.use(VueCookies);

// Use the Toast plugin
app.use(Toast, {
    timeout: 3000,
    /* Add Toast options here if needed */
});

// Mount the app to the #app element in your HTML
app.mount('#app');
