<template>
    <div v-if="editMode">
        <div class="tooltip tooltip-right" data-tip="Culoare Fundal">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg"
                @click="openBgColorEditModal"
            >
                <font-awesome-icon icon="fa-solid fa-palette" />
            </button>
        </div>

        <ModalComponent
            v-if="isBgColorEditModalOpen"
            :is-open="isBgColorEditModalOpen"
            @close="closeBgColorEditModal"
        >
            <h3 class="font-bold text-lg">Schimba culoare fundal</h3>
            <button
                @click="closeBgColorEditModal"
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
                ✕
            </button>
            <div class="py-4">
                <div class="flex justify-center items-center gap-2">
                    <label>{{ config._displayName }}</label>
                    <input type="color" :value="value" @input="updateValue" />
                </div>
            </div>
        </ModalComponent>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    props: {
        value: String,
        config: Object,
        section: Object,
        sectionKey: String,
        propertyKey: String,
    },
    components: {
        ModalComponent,
    },
    data() {
        return {
            editedValue: this.value,
            isBgColorEditModalOpen: false,
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        openBgColorEditModal() {
            this.isBgColorEditModalOpen = true;
        },
        closeBgColorEditModal() {
            this.isBgColorEditModalOpen = false;
        },
        updateValue(event) {
            this.editedValue = event.target.value;
            this.saveEdit();
        },
        saveEdit() {
            this.updateNestedValue(
                this.section,
                this.propertyKey,
                this.editedValue
            );
            this.$emit('update-section', this.sectionKey, this.section);
        },
        updateNestedValue(obj, path, value) {
            const keys = path.split('.');
            let o = obj;
            for (let i = 0; i < keys.length - 1; i++) {
                o = o[keys[i]];
            }
            o[keys[keys.length - 1]] = value;
        },
    },
};
</script>

<style scoped></style>
