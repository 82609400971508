import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { checkAuthentication } from '../utils/auth'; // Import checkAuthentication
import ProjectDetails from '../views/ProjectDetails.vue';
import PublicInvitation from '../views/PublicInvitation.vue';
import ProjectDetailsInvitations from '../views/ProjectDetailsInvitations.vue';
import ProjectDetailsStats from '../views/ProjectDetailsStats.vue';
import ProjectDetailsConfig from '../views/ProjectDetailsConfig.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'InvitatiiSmart.ro - Crează invitații digitale pentru evenimente',
            description:
                'Creează și distribuie invitații digitale personalizate pentru nunți, botezuri, aniversări și alte evenimente speciale.',
        },
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: {
            title: 'Despre noi - InvitatiiSmart.ro',
            description:
                'Află mai multe despre InvitatiiSmart.ro și cum îți putem ajuta să creezi invitații digitale personalizate.',
        },
    },
    {
        path: '/howto',
        name: 'How To',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/HowTo.vue'),
        meta: {
            title: 'Cum funcționează - Ghid utilizare | InvitatiiSmart.ro',
            description:
                'Un ghid detaliat despre cum să creezi și să personalizezi invitații digitale folosind InvitatiiSmart.ro.',
        },
    },
    {
        path: '/cart',
        name: 'cart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CartView.vue'),
        meta: {
            title: 'Coșul de cumpărături - InvitatiiSmart.ro',
            description:
                'Verifică și finalizează comenzile pentru invitațiile tale digitale personalizate.',
        },
    },
    {
        path: '/pachete',
        name: 'pachete',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ServicesView.vue'),
        meta: {
            title: 'Pachete și prețuri - InvitatiiSmart.ro',
            description:
                'Descoperă pachetele noastre și alege opțiunea potrivită pentru invitațiile tale digitale.',
        },
    },

    {
        path: '/contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ContactForm.vue'),
        meta: {
            title: 'Contact - InvitatiiSmart.ro',
            description:
                'Ai întrebări? Contactează-ne pentru suport și informații despre serviciile noastre.',
        },
    },
    {
        path: '/checkoutDone',
        name: 'Checkout Done',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CheckoutDone.vue'),
    },
    {
        path: '/checkoutError',
        name: 'Checkout Error',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/CheckoutError.vue'
            ),
    },
    {
        path: '/checkout',
        name: 'checkout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CheckoutView.vue'),
        meta: {
            title: 'Finalizare comandă - InvitatiiSmart.ro',
            description:
                'Completează procesul de achiziție și creează invitații digitale personalizate pentru evenimentul tău.',
        },
    },
    {
        path: '/public/:projectId/:invitationId/:projectSlug',
        name: 'PublicInvitationName',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
        }),
    },
    {
        path: '/public/:projectId/:invitationId',
        name: 'PublicInvitation',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
        }),
    },
    {
        path: '/ps/:projectId/:projectSlug',
        name: 'PublicInvitationSimpleName',
        component: PublicInvitation,
        props: () => ({
            isSimple: true,
        }),
    },
    {
        path: '/ps/:projectId',
        name: 'PublicInvitationSimple',
        component: PublicInvitation,
        props: () => ({
            isSimple: true,
        }),
    },

    {
        path: '/enterprise/:templateId',
        name: 'Template Edit',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
            isTemplateEdit: true,
        }),
    },

    {
        path: '/admin',
        name: 'Admin Dashboard',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/CustomersView.vue'
            ),
    },

    {
        path: '/admin/credits',
        name: 'Admin Credits',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/CreditsView.vue'
            ),
    },

    {
        path: '/admin/customers',
        name: 'Admin Customers',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/CustomersView.vue'
            ),
    },

    {
        path: '/admin/templates',
        name: 'Admin Templates',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/TemplatesView.vue'
            ),
    },

    {
        path: '/admin/settings',
        name: 'Admin Settings',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/SettingsView.vue'
            ),
    },
    {
        path: '/userConfig',
        name: 'userConfig',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/UserConfig.vue'),
    },
    {
        path: '/UserConfigSettings',
        name: 'userConfigSettings',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/UserConfigSettings.vue'
            ),
    },
    {
        path: '/projects',
        name: 'projects',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    },
    {
        path: '/resetPassword',
        name: 'Reset Password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/ResetPassword.vue'
            ),
    },
    {
        path: '/forgotPassword',
        name: 'Forgot Password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/ForgotPassword.vue'
            ),
    },
    {
        path: '/register',
        name: 'register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next('/');
            } else {
                next(); // Allow access
            }
        },
        meta: {
            title: 'Înregistrare - InvitatiiSmart.ro',
            description:
                'Creează un cont pe InvitatiiSmart.ro și începe să personalizezi invitațiile tale digitale.',
        },
    },
    {
        path: '/termsAndConditions',
        name: 'Termeni si conditii de utilizare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/TermsAndConditions.vue'
            ),
        meta: {
            title: 'Termeni și condiții - InvitatiiSmart.ro',
            description:
                'Citește termenii și condițiile de utilizare ale platformei InvitatiiSmart.ro.',
        },
    },
    {
        path: '/privacyPolicy',
        name: 'Politica de Confidentialitate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'
            ),
        meta: {
            title: 'Politica de confidențialitate - InvitatiiSmart.ro',
            description:
                'Află cum protejăm și gestionăm datele tale personale pe InvitatiiSmart.ro.',
        },
    },
    {
        path: '/cookiePolicy',
        name: 'Politica de Cookies',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CookiePolicy.vue'),
        meta: {
            title: 'Politica de cookies - InvitatiiSmart.ro',
            description:
                'Informații despre utilizarea cookie-urilor pe InvitatiiSmart.ro.',
        },
    },
    {
        path: '/project/:projectId/:projectSlug',
        name: 'ProjectDetails',
        component: ProjectDetails,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        meta: {
            title: 'Detalii proiect - InvitatiiSmart.ro',
            description:
                'Vizualizează și gestionează detaliile invitației tale digitale.',
        },
    },
    {
        path: '/project/:projectId/:projectSlug/invitati',
        name: 'ProjectDetailsInvitations',
        component: ProjectDetailsInvitations,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectSlug/config',
        name: 'ProjectDetailsConfig',
        component: ProjectDetailsConfig,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectSlug/statistici',
        name: 'ProjectDetailsStats',
        component: ProjectDetailsStats,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/addProject',
        name: 'addProject',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AddProject.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

// **Global Before Each Hook for SEO Metadata**
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'InvitatiiSmart.ro - Invitatii digitale';
    const descriptionTag = document.querySelector('meta[name="description"]');
    if (descriptionTag) {
        descriptionTag.setAttribute(
            'content',
            to.meta.description ||
                'Creează și personalizează invitații digitale pentru evenimente speciale.'
        );
    } else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'description';
        metaTag.content =
            to.meta.description ||
            'Creează și personalizează invitații digitale pentru evenimente speciale.';
        document.head.appendChild(metaTag);
    }
    next();
});

export default router;
