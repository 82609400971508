<template>
    <div>
        <div class="projects">
            <div class="flex flex-wrap">
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossorigin
                />

                <MasterEditButton v-if="isOwner" />
                <AddSection v-if="isOwner" @add-section="addSection" />

                <button
                    v-if="isOwner"
                    :onClick="saveTemplateJsonData"
                    class="fixed z-20 top-7 md:top-10 btn md:btn-md text-xs md:text-base btn-primary transition-all duration-500"
                    :class="
                        editMode
                            ? 'left-20 md:left-24 btn-sm opacity-100'
                            : 'left-10 md:left-10 btn-sm opacity-0 pointer-events-none'
                    "
                >
                    <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
                    Salveaza
                </button>

                <div
                    class="w-full relative"
                    v-for="(section, sectionKey) in templateMock"
                    :key="sectionKey"
                >
                    <!-- Floating Images Component -->
                    <FloatingImages
                        v-if="section.showFloatingBaloons"
                        :images="imageData"
                    />
                    <!-- sidebar menu     -->
                    <div
                        v-if="isOwner"
                        class="absolute z-20 left-5 md:left-10 top-40 md:top-40 flex flex-col gap-2.5 transition-all"
                        :class="
                            editMode
                                ? 'opacity-100'
                                : 'opacity-0 pointer-events-none'
                        "
                    >
                        <BackgroundImageEditor
                            :value="section.backgroundImage.value"
                            :section="section"
                            :sectionKey="sectionKey"
                            propertyKey="backgroundImage.value"
                            :config="section.backgroundImage._config"
                            @edit-background-image="
                                setEditedKeys(sectionKey, 'backgroundImage')
                            "
                        />

                        <BackgroundColorEditor
                            :value="section.backgroundColor.value"
                            :section="section"
                            :sectionKey="sectionKey"
                            propertyKey="backgroundColor.value"
                            :config="section.backgroundColor._config"
                            @update-section="updateSection"
                        />

                        <MaskedImagesEditor
                            :section="section"
                            :sectionKey="sectionKey"
                            @edit-image="setEditedKeys"
                            @delete-masked-images="delete section.maskedImages"
                            @update-section="updateSection"
                        />

                        <CountdownTimerEditor
                            :section="section"
                            :sectionKey="sectionKey"
                            @update-section="updateSection"
                        />

                        <LocationElementsEditor
                            :section="section"
                            :sectionKey="sectionKey"
                            @edit-image="setEditedKeys"
                            @update-section="updateSection"
                        />

                        <UpdateSectionOrder
                            :section="section"
                            :sectionKey="sectionKey"
                            :sectionCount="getSectionCount()"
                            @update-order="updateSectionOrder"
                        />

                        <DeleteSection
                            :section="section"
                            :sectionKey="String(sectionKey)"
                            @delete-section="deleteSection(sectionKey)"
                        />
                    </div>
                    <div
                        class="md:px-10 bg-cover bg-center parallax-section"
                        :class="section.plainImage ? 'py-0 px-0' : 'py-20 px-5'"
                        :style="{
                            backgroundImage: section.plainImage
                                ? ''
                                : `url('${section.backgroundImage.value}')`,
                            backgroundColor: section.backgroundColor.value,
                            minHeight: section.plainImage
                                ? 'auto'
                                : section.minHeight._value,
                        }"
                    >
                        <div class="relative z-10">
                            <div>
                                <p
                                    :style="{
                                        fontFamily:
                                            section.title.fontFamily._value,
                                        fontSize: `${
                                            isMobile
                                                ? section.title.fontSize.value >
                                                  70
                                                    ? section.title.fontSize
                                                          .value / 2
                                                    : section.title.fontSize
                                                          .value
                                                : section.title.fontSize.value
                                        }px`,
                                        color: section.title.textColor.value,
                                        marginTop: `${section.title.marginTop.value}vh`,
                                        'text-shadow':
                                            (section.title.text.textShadow &&
                                                section.title.text.textShadow ==
                                                    true) ||
                                            section.title.text.textShadow ==
                                                'true'
                                                ? textShadow
                                                : '',
                                    }"
                                    :class="[section.title._CSSclass.value]"
                                    v-html="
                                        section.title.text.value.replace(
                                            /\n/g,
                                            '<br>'
                                        )
                                    "
                                ></p>

                                <!-- Inline edit buttons -->
                                <div
                                    class="flex justify-center gap-1 md:gap-2.5 transition-all"
                                    :class="
                                        editMode
                                            ? 'opacity-100'
                                            : 'opacity-0 pointer-events-none'
                                    "
                                >
                                    <TextEditor
                                        :value="section.title.text.value"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="title.text.value"
                                        :config="section.title.text._config"
                                        @update-section="updateSection"
                                    />
                                    <TextColorEditor
                                        :value="section.title.textColor.value"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="title.textColor.value"
                                        :config="
                                            section.title.textColor._config
                                        "
                                        @update-section="updateSection"
                                    />
                                    <TextSizeEditor
                                        :value="section.title.fontSize.value"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="title.fontSize.value"
                                        :config="section.title.fontSize._config"
                                        @update-section="updateSection"
                                    />
                                    <TextShadowEditor
                                        :value="section.title.text.textShadow"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="title.text.textShadow"
                                        :config="section.title.text._config"
                                        @update-section="updateSection"
                                    />
                                    <MarginTopEditor
                                        :value="section.title.marginTop.value"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="title.marginTop.value"
                                        :config="
                                            section.title.marginTop._config
                                        "
                                        @update-section="updateSection"
                                    />
                                    <FontFamilyEditor
                                        :value="section.title.fontFamily._value"
                                        :fonts="fontFamilyCSS"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="title.fontFamily._value"
                                        :config="
                                            section.title.fontFamily._config
                                        "
                                        @update-section="updateSection"
                                    />
                                </div>
                            </div>

                            <div>
                                <p
                                    :style="{
                                        fontFamily:
                                            section.description.fontFamily
                                                ._value,
                                        fontSize: `${section.description.fontSize.value}px`,
                                        color: section.description.textColor
                                            .value,
                                        marginTop: `${section.description.marginTop.value}vh`,
                                        'text-shadow': section.description.text
                                            .textShadow
                                            ? textShadow
                                            : '',
                                    }"
                                    :class="[
                                        section.description._CSSclass.value,
                                    ]"
                                    v-html="
                                        section.description.text.value.replace(
                                            /\n/g,
                                            '<br>'
                                        )
                                    "
                                ></p>
                                <!-- Inline edit buttons -->
                                <div
                                    class="flex justify-center gap-2.5 transition-all"
                                    :class="
                                        editMode
                                            ? 'opacity-100'
                                            : 'opacity-0 pointer-events-none'
                                    "
                                >
                                    <TextEditor
                                        :value="section.description.text.value"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="description.text.value"
                                        :config="
                                            section.description.text._config
                                        "
                                        @update-section="updateSection"
                                    />
                                    <TextColorEditor
                                        :value="
                                            section.description.textColor.value
                                        "
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="description.textColor.value"
                                        :config="
                                            section.description.textColor
                                                ._config
                                        "
                                        @update-section="updateSection"
                                    />
                                    <TextSizeEditor
                                        :value="
                                            section.description.fontSize.value
                                        "
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="description.fontSize.value"
                                        :config="section.title.fontSize._config"
                                        @update-section="updateSection"
                                    />
                                    <TextShadowEditor
                                        :value="
                                            section.description.text.textShadow
                                        "
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="description.text.textShadow"
                                        :config="
                                            section.description.text._config
                                        "
                                        @update-section="updateSection"
                                    />
                                    <MarginTopEditor
                                        :value="
                                            section.description.marginTop.value
                                        "
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="description.marginTop.value"
                                        :config="
                                            section.description.marginTop
                                                ._config
                                        "
                                        @update-section="updateSection"
                                    />
                                    <FontFamilyEditor
                                        :value="
                                            section.description.fontFamily
                                                ._value
                                        "
                                        :fonts="fontFamilyCSS"
                                        :section="section"
                                        :sectionKey="sectionKey"
                                        propertyKey="description.fontFamily._value"
                                        :config="
                                            section.description.fontFamily
                                                ._config
                                        "
                                        @update-section="updateSection"
                                    />
                                </div>
                            </div>

                            <p
                                v-if="section.description.text.value2"
                                :style="{
                                    fontFamily:
                                        section.description.fontFamily._value,
                                    fontSize: `${section.description.fontSize.value}px`,
                                    color: section.description.textColor.value,
                                    marginTop: `${section.description.marginTop.value}vh`,
                                    'text-shadow': section.description.text
                                        .textShadow
                                        ? textShadow
                                        : '',
                                }"
                                :class="[section.description._CSSclass.value]"
                            >
                                {{ `${section.description.text.value2}` }}
                            </p>
                            <p
                                v-if="section.description.text.value3"
                                :style="{
                                    fontFamily:
                                        section.description.fontFamily._value,
                                    fontSize: `${section.description.fontSize.value}px`,
                                    color: section.description.textColor.value,
                                    marginTop: `${section.description.marginTop.value}vh`,
                                    'text-shadow': section.description.text
                                        .textShadow
                                        ? textShadow
                                        : '',
                                }"
                                :class="[section.description._CSSclass.value]"
                            >
                                {{ `${section.description.text.value3}` }}
                            </p>

                            <!-- Masked Images Start -->
                            <div
                                v-if="section.maskedImages"
                                class="flex w-full justify-center space-x-10 mt-10 drop-shadow-lg"
                            >
                                <img
                                    class="mask mask-heart h-28 md:h-40"
                                    :src="`${section.maskedImages.image1}`"
                                />
                                <img
                                    class="mask mask-heart h-28 md:h-40"
                                    :src="`${section.maskedImages.image2}`"
                                />
                            </div>
                            <!-- Masked Images End -->

                            <!-- Location Items Start -->
                            <div
                                v-if="section.locationElements"
                                class="flex w-full flex-wrap md:flex-nowrap justify-center md:space-x-4 mt-10 max-w-max md:max-w-full"
                            >
                                <div
                                    v-for="(
                                        location, locationKey
                                    ) in section.locationElements"
                                    :key="location"
                                >
                                    <div
                                        class="card md:w-96 bg-base-100 shadow-xl mt-5"
                                        v-if="
                                            section.locationElements[
                                                locationKey
                                            ].visible == true ||
                                            section.locationElements[
                                                locationKey
                                            ].visible == 'true'
                                        "
                                    >
                                        <figure>
                                            <img
                                                :src="`${section.locationElements[locationKey].image}`"
                                                alt="locatie"
                                            />
                                        </figure>
                                        <div class="card-body text-left">
                                            <h2 class="card-title">
                                                {{
                                                    section.locationElements[
                                                        locationKey
                                                    ].locationTitle
                                                }}
                                            </h2>
                                            <div>
                                                {{
                                                    section.locationElements[
                                                        locationKey
                                                    ].date
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    section.locationElements[
                                                        locationKey
                                                    ].locationName
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    section.locationElements[
                                                        locationKey
                                                    ].locationAddress
                                                }}
                                            </div>
                                            <div
                                                v-if="
                                                    section.locationElements[
                                                        locationKey
                                                    ].locationCoordonates
                                                "
                                            >
                                                <a
                                                    class="btn"
                                                    target="_blank"
                                                    :href="`https://www.google.com/maps?q=${section.locationElements[locationKey].locationCoordonates}`"
                                                >
                                                    <font-awesome-icon
                                                        icon="fa-solid fa-map-location-dot"
                                                    />
                                                    Vezi pe harta
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="section.countdownActive" class="mt-10">
                                <Countdown
                                    :deadlineDate="projectDate"
                                    :labels="{
                                        days: 'Zile',
                                        hours: 'Ore',
                                        minutes: 'Minute',
                                        seconds: 'Secunde',
                                    }"
                                    :labelColor="'#ffffff'"
                                    :mainColor="'#ffffff'"
                                />
                            </div>

                            <!-- Location Items End -->
                            <template
                                v-if="section._sectionType.value === 'form'"
                            >
                                <InvitationForm
                                    :projectId="projectId"
                                    :invitationId="invitationId"
                                    :section="section"
                                    :sectionKey="sectionKey"
                                    :isOwner="isOwner"
                                    @update-section="updateSection"
                                />
                            </template>
                        </div>

                        <img
                            class="v-full"
                            v-if="section.plainImage"
                            :src="`${section.backgroundImage.value}`"
                        />
                    </div>
                </div>
            </div>

            <!-- Display images -->
            <div
                :class="{
                    fixed: !imageModalClosed,
                    hidden: imageModalClosed,
                }"
                class="inset-0 flex md:items-center justify-center static z-50 overflow-y-hidden"
            >
                <div class="absolute bg-black opacity-50 inset-0 z-50"></div>
                <div
                    class="z-50 relative rounded-xl shadow-lg w-full md:w-1/2 h-full md:h-2/3 overflow-auto"
                    :class="
                        image.src && !fileUploadInProgress
                            ? 'bg-black'
                            : 'bg-white'
                    "
                >
                    <div
                        v-if="fileUploadInProgress"
                        class="absolute h-full w-full flex justify-center align-middle"
                    >
                        <div class="loading loading-bars loading-lg"></div>
                    </div>

                    <div class="p-10">
                        <font-awesome-icon
                            icon="fa-solid fa-circle-xmark"
                            class="absolute right-5 top-5 text-3xl hover:text-red-300 cursor-pointer"
                            @click="toggleImageModal()"
                        />

                        <!-- Modal content -->
                        <div
                            class="w-full left-0 top-0 z-30 h-3/4 absolute"
                            :class="{ hidden: fileUploadInProgress }"
                            v-if="image.src"
                        >
                            <cropper
                                ref="cropper"
                                class="upload-cropper"
                                :src="image.src"
                                :stencil-props="{
                                    movable: cropperOptions.movable,
                                    resizable: cropperOptions.resizable,
                                }"
                            />
                            <div class="actions mt-5">
                                <button
                                    @click="image.src = null"
                                    class="btn btn-outline btn-base-100"
                                >
                                    Anuleaza
                                </button>
                                <button
                                    @click="crop"
                                    class="btn btn-primary ml-10"
                                    :class="{
                                        'btn-disabled': fileUploadInProgress,
                                    }"
                                >
                                    Salveaza
                                </button>
                            </div>
                        </div>

                        <div v-if="!image.src">
                            <h1 class="text-2xl font-bold mb-4">
                                Galerie Foto
                            </h1>
                            <p class="mb-5">
                                Adauga sau selecteaza imagini din galeria ta
                            </p>
                            <div class="mb-10" v-if="!isTemplateEdit">
                                <label for="large-file-input" class="sr-only"
                                    >Choose file</label
                                >
                                <div
                                    id="large-file-input"
                                    class="block cursor-pointer transition relative w-full border border-green-800 bg-green-600 text-white shadow hover:shadow-xl rounded-lg text-sm focus:z-10 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-3 file:px-4 file:sm:py-5"
                                >
                                    <div
                                        class="h-16 cursor-pointer flex text-xl items-center px-5"
                                        @click="$refs.file.click()"
                                    >
                                        <div class=" ">
                                            <font-awesome-icon
                                                icon="fa-solid fa-cloud-arrow-up"
                                                class="mr-10 ml-5"
                                            />
                                        </div>
                                        <div class="font-bold">
                                            Incarca imagine
                                        </div>
                                    </div>

                                    <input
                                        type="file"
                                        name="large-file-input"
                                        @change="loadImage($event)"
                                        accept="image/png, image/jpeg"
                                        class="absolute cursor-pointer opacity-0 left-0 top-0 w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 file:bg-gray-50 file:border-0 file:me-4 file:py-3 file:px-4 file:sm:py-5 dark:file:bg-gray-700 dark:file:text-gray-400"
                                    />
                                </div>
                            </div>

                            <div
                                class="block border border-b-2 p-5 mb-5 relative rounded-lg"
                            >
                                <button
                                    v-if="
                                        templateMock &&
                                        templateMock[editedSectionKey] &&
                                        templateMock[editedSectionKey]
                                            .backgroundImage &&
                                        templateMock[editedSectionKey]
                                            .backgroundImage.value
                                    "
                                    class="mb-10 btn btn-xs btn-error absolute right-2 top-2"
                                    @click="removeBackgroundImage"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-xmark"
                                    />
                                    Sterge Imaginea de fundal
                                </button>
                                <div class="font-bold mb-5">
                                    Imaginile noastre
                                </div>
                                <div
                                    class="grid grid-cols-2 md:grid-cols-4 gap-4"
                                >
                                    <div
                                        v-for="(image, index) in templateImages"
                                        :key="index"
                                    >
                                        <div>
                                            <img
                                                :src="`${image}`"
                                                class="h-auto max-w-full rounded-lg shadow-md"
                                                alt="Project Image"
                                            />
                                        </div>

                                        <button
                                            class="bg-green-500 mr-2 text-white px-4 py-2 rounded hover:bg-green-600 mt-5"
                                            @click="selectImage(image)"
                                        >
                                            Selecteaza
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="block border p-5 rounded-lg">
                                <div class="font-bold mb-5">Imaginile tale</div>
                                <div
                                    class="grid grid-cols-2 md:grid-cols-4 gap-4"
                                >
                                    <div v-if="imagesData.length == 0">
                                        Nu ai nici o imagine disponibila.
                                    </div>
                                    <div
                                        v-for="(image, index) in imagesData"
                                        :key="index"
                                    >
                                        <div>
                                            <img
                                                :src="`${image}`"
                                                class="h-auto max-w-full rounded-lg shadow-md"
                                                alt="Project Image"
                                            />
                                        </div>

                                        <button
                                            class="bg-green-500 mr-2 text-white px-4 py-2 rounded hover:bg-green-600 mt-5"
                                            @click="selectImage(image)"
                                        >
                                            Selecteaza
                                        </button>
                                        <button
                                            class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mt-5"
                                            @click="deleteImage(image)"
                                        >
                                            Sterge
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import InvitationForm from '@/components/InvitationFormConfigurable.vue';
import { useToast } from 'vue-toastification';
import { Countdown } from 'vue3-flip-countdown';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { mapGetters } from 'vuex';

import FloatingImages from '@/components/FloatingImages.vue';

import MasterEditButton from '@/components/editorUtilsInline/MasterEditButton.vue';
import AddSection from '@/components/editorUtilsInline/AddSection.vue';

// Section sidebar
import BackgroundImageEditor from '@/components/editorUtilsInline/sectionSidebar/BackgroundImageEditor.vue';
import BackgroundColorEditor from '@/components/editorUtilsInline/sectionSidebar/BackgroundColorEditor.vue';
import MaskedImagesEditor from '@/components/editorUtilsInline/sectionSidebar/MaskedImagesEditor.vue';
import CountdownTimerEditor from '@/components/editorUtilsInline/sectionSidebar/CountdownTimerEditor.vue';
import LocationElementsEditor from '@/components/editorUtilsInline/sectionSidebar/LocationElementsEditor.vue';
import DeleteSection from '@/components/editorUtilsInline/sectionSidebar/DeleteSection.vue';
import UpdateSectionOrder from '@/components/editorUtilsInline/sectionSidebar/UpdateSectionOrder.vue';

// Text tools
import TextEditor from '@/components/editorUtilsInline/TextEditor';
import TextColorEditor from '@/components/editorUtilsInline/TextColorEditor.vue';
import TextSizeEditor from '@/components/editorUtilsInline/TextSizeEditor.vue';
import TextShadowEditor from '@/components/editorUtilsInline/TextShadowEditor.vue';
import FontFamilyEditor from '@/components/editorUtilsInline/FontFamilyEditor.vue';
import MarginTopEditor from '@/components/editorUtilsInline/MarginTopEditor.vue';

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
    const byteArray = new Uint8Array(file).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case '89504e47':
            return 'image/png';
        case '47494638':
            return 'image/gif';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
            return 'image/jpeg';
        default:
            return fallback;
    }
}

export default {
    name: 'PublicInvitation',
    components: {
        InvitationForm,
        Cropper,
        Countdown,
        TextEditor,
        TextColorEditor,
        MasterEditButton,
        TextSizeEditor,
        TextShadowEditor,
        FontFamilyEditor,
        BackgroundImageEditor,
        BackgroundColorEditor,
        MaskedImagesEditor,
        CountdownTimerEditor,
        LocationElementsEditor,
        MarginTopEditor,
        DeleteSection,
        AddSection,
        UpdateSectionOrder,
        FloatingImages,
    },
    props: {
        isSimple: {
            type: Boolean,
            default: false,
        },
        isTemplateEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    data() {
        return {
            fileUploadInProgress: false,
            isMobile: false,
            image: {
                src: null,
                type: null,
                fileName: null,
            },
            text: '',
            cropperOptions: {
                aspectRatio: null,
                movable: true,
                resizable: true,
            },
            projectId: null,
            powerAdmin: false,
            projectName: null,
            invitationId: null,
            templateContent: null,
            templateId: null,
            isOwner: false,
            imagesData: [],
            templateImages: [],
            imageModalClosed: true,
            editedSectionKey: null,
            editedPropertyKey: null,
            editedNestedPropertyKey: null,
            editedValueKey: null,
            editedSubPropertyKey: null,
            templateMock: {},
            textShadow:
                '3px 5px 7px rgba(0, 0, 0, 1), 1px -1px 2px rgba(69, 65, 75, 0.39)',
            fontFamilyList: [
                'Dancing+Script:wght@400;700',
                'Alex+Brush',
                'Mrs+Saint+Delafield',
                'Parisienne',
                'Pinyon+Script',
            ],
            fontFamilyCSS: [
                "'Alex Brush', cursive",
                "'Dancing Script', cursive",
                "'Mrs Saint Delafield', cursive",
                "'Parisienne', cursive",
                "'Pinyon Script', cursive",
            ],
            changesDetected: false,
            removeImageSelectedSection: null,
            imageData: [
                {
                    src: 'project-images/baloon-1.png',
                    alt: 'Image 1',
                    parallax: 0.5,
                    floatSpeed: 2,
                    style: { top: '40%', left: '10%' },
                },
                {
                    src: 'project-images/baloon-1.png',
                    alt: 'Image 2',
                    parallax: 0.3,
                    floatSpeed: 3,
                    style: { top: '30%', left: '30%' },
                },
                {
                    src: 'project-images/baloon-1.png',
                    alt: 'Image 3',
                    parallax: 0.7,
                    floatSpeed: 1.5,
                    style: { top: '50%', left: '70%' },
                },
                {
                    src: 'project-images/baloon-1.png',
                    alt: 'Image 3',
                    parallax: 0.7,
                    floatSpeed: 4.5,
                    style: { top: '40%', left: '60%' },
                },
            ],
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        // Check if the user is on a mobile device
        this.isMobile = window.innerWidth <= 767; // Adjust the threshold based on your design
        // Listen for window resize events to update the isMobile flag
        window.addEventListener('resize', this.handleResize);

        this.loadFontFamily();
    },
    unmounted() {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src);
        }

        window.removeEventListener('scroll', this.handleScroll);

        // Remove the event listener to avoid memory leaks
        window.removeEventListener('resize', this.handleResize);
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    created() {
        const currentRoute = this.$route;
        // Access the route parameters
        this.projectId = currentRoute.params.projectId;
        this.projectName = currentRoute.params.projectName;
        this.invitationId = currentRoute.params.invitationId;

        // Check if the user viewing the page is the actual owner to avoid opened_time registration
        if (localStorage.getItem('ownedProjects')) {
            const ownedProjects = JSON.parse(
                localStorage.getItem('ownedProjects')
            );
            const targetProjectId = this.projectId;
            const isInOwnedProjects = ownedProjects.some(
                (project) => project.project_id == targetProjectId
            );

            if (isInOwnedProjects) {
                this.isOwner = true;
            }
        }

        if (this.isTemplateEdit) {
            this.isOwner = true;
            this.getTemplateData(this.$route.params.templateId);
        } else {
            this.getTemplateData();
        }

        this.handleScroll();
    },
    watch: {
        // templateMock: {
        //     handler(newVal, oldVal) {
        //         this.onJsonDataUpdated(newVal, oldVal);
        //     },
        //     deep: true,
        // },
    },
    methods: {
        // onJsonDataUpdated(newVal, oldVal) {
        //     // Your logic to handle the update
        //     // console.log('JSON data updated oldVal:', oldVal);
        //     console.log('JSON data updated newVal:', newVal);
        //     console.log('JSON data updated oldVal:', oldVal);
        //     console.log('templateMock', this.templateMock);
        //     if (this.templateMock && oldVal && this.templateMock === oldVal) {
        //         this.changesDetected = true;
        //     }
        //     console.log(this.templateMock === oldVal);
        // },
        loadFontFamily() {
            // Dynamically load font families
            this.fontFamilyList.forEach((fontFamily) => {
                const fontLink = document.createElement('link');
                fontLink.rel = 'stylesheet';
                fontLink.href = `https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`;
                document.head.appendChild(fontLink);
            });
        },
        handleScroll() {
            const parallaxSections =
                document.querySelectorAll('.parallax-section');

            parallaxSections.forEach((section) => {
                const rect = section.getBoundingClientRect();
                const distanceFromTop = rect.top;

                // Adjust the speed and offset as needed
                const speed = section.dataset.speed || 0.2;
                const offset = section.dataset.offset || 0;

                // Calculate the new background position based on the distance from the top
                const yPos = -(distanceFromTop * speed) + offset;

                section.style.backgroundPosition = `50% ${yPos}px`;
            });
        },
        crop() {
            let { canvas } = this.$refs.cropper.getResult();
            if (canvas) {
                canvas.toBlob((blob) => {
                    this.handleFileUpload(blob, this.image.fileName);

                    // Perhaps you should add the setting appropriate file format here
                }, this.image.type);
            }
        },
        loadImage(event) {
            // Reference to the DOM input element
            const { files } = event.target;
            const fileName = this.getFileNameFromPath(event.target.value);
            // Ensure that you have a file before attempting to read it
            if (files && files[0] && fileName) {
                // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src);
                }
                // 2. Create the blob link to the file to optimize performance:
                const blob = URL.createObjectURL(files[0]);

                // 3. The steps below are designated to determine a file mime type to use it during the
                // getting of a cropped image from the canvas. You can replace it them by the following string,
                // but the type will be derived from the extension and it can lead to an incorrect result:
                //
                // this.image = {
                //    src: blob;
                //    type: files[0].type
                // }

                // Create a new FileReader to read this image binary data
                const reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                    this.image = {
                        // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                        src: blob,
                        // Determine the image type to preserve it during the extracting the image from canvas:
                        type: getMimeType(e.target.result, files[0].type),
                        fileName: fileName,
                    };
                };
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsArrayBuffer(files[0]);
            }
        },
        getFileNameFromPath(filePath) {
            // Split the path by "\" or "/" to handle different browsers
            const pathSegments = filePath.split(/[\\/]/);

            // Get the last segment, which is the filename
            const fileName = pathSegments[pathSegments.length - 1];

            return fileName;
        },
        setEditedKeys(sectionKey, propertyKey, valueKey, subPropertyKey) {
            this.editedSectionKey = sectionKey;
            this.editedPropertyKey = propertyKey;
            this.editedValueKey = valueKey || null;
            this.editedSubPropertyKey = subPropertyKey || null;

            this.toggleImageModal();
        },
        selectImage(image) {
            // Extract the underlying objects from Proxy
            const sectionKey = this.editedSectionKey;
            const propertyKey = this.editedPropertyKey;
            const valueKey = this.editedValueKey || 'value';
            const subPropertyKey = this.editedSubPropertyKey;

            if (
                sectionKey !== null &&
                propertyKey !== null &&
                this.templateMock[sectionKey] &&
                this.templateMock[sectionKey][propertyKey]
            ) {
                if (subPropertyKey) {
                    // Update the corresponding property in templateMock
                    this.templateMock[sectionKey][propertyKey][subPropertyKey][
                        valueKey
                    ] = image;
                } else {
                    // Update the corresponding property in templateMock
                    this.templateMock[sectionKey][propertyKey][valueKey] =
                        image;
                }

                this.toggleImageModal();
            } else {
                console.error('Invalid section or property.');
            }
        },

        toggleImageModal() {
            this.imageModalClosed = !this.imageModalClosed;
        },
        deleteImage(imagePath) {
            const formData = {
                imagePath: imagePath,
                projectId: this.projectId,
            };
            // Retrieve user_id from localStorage
            const jwtToken = localStorage.getItem('userToken');

            axios
                .post('/api/index.php?endpoint=deleteImage', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                })
                .then((result) => {
                    if (result.data.success) {
                        this.imagesData = this.imagesData.filter(
                            (image) => image !== imagePath
                        );
                    }
                })
                .catch((error) => {
                    console.error('Error uploading image', error);
                });
        },
        async handleFileUpload(file, fileName) {
            this.fileUploadInProgress = true;

            if (file && fileName) {
                console.log(file);
                // Check file type
                const allowedTypes = ['image/jpeg', 'image/png'];
                if (!allowedTypes.includes(file.type)) {
                    this.toast.error(
                        `Eroare! Imaginea trebuie sa fie in format JPG sau PNG.`
                    );

                    this.fileUploadInProgress = false;

                    return;
                }

                if (!this.powerAdmin) {
                    // Check file size
                    const maxSizeMB = 2;
                    const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes
                    if (file.size > maxSizeBytes) {
                        this.toast.error(
                            `Eroare! Imaginea trebuie sa nu depaseasca ${maxSizeMB}MB.`
                        );

                        this.fileUploadInProgress = false;
                        return;
                    }

                    // Check image dimensions
                    const maxWidth = 2000;
                    const maxHeight = 2000;
                    try {
                        await this.isValidImageDimensions(
                            file,
                            maxWidth,
                            maxHeight
                        );
                    } catch (error) {
                        this.toast.error(`Eroare! ${error}.`);

                        this.fileUploadInProgress = false;
                        return;
                    }
                }

                const formData = new FormData();
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');

                formData.append('file', file, fileName);
                formData.append('projectId', this.projectId);

                axios
                    .post('/api/index.php?endpoint=uploadImage', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    })
                    .then((response) => {
                        // Assuming the backend sends an object with imagePath property
                        const imagePath = response.data.imagePath;
                        // Append the new image path to the imagesData array
                        this.imagesData.push(imagePath);
                        // Handle the response from the backend, e.g., update the UI or store the image path.
                        this.image.src = null;

                        this.fileUploadInProgress = false;
                    })
                    .catch((error) => {
                        this.fileUploadInProgress = false;
                        console.error('Error uploading image', error);
                    });
            }
        },
        // Utility function to check if the image dimensions are valid
        isValidImageDimensions(file, maxWidth, maxHeight) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        if (img.width <= maxWidth && img.height <= maxHeight) {
                            resolve();
                        } else {
                            reject(
                                `Imaginea trebuie sa nu depaseasca ${maxWidth}x${maxHeight} px.`
                            );
                        }
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            });
        },
        async saveTemplateJsonData() {
            // console.log('data to be saved', JSON.stringify(this.templateMock));
            try {
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');

                let endpoint = `/api/index.php?endpoint=saveTemplate`;

                // Assuming you have form data available in Vue data properties
                const formData = {
                    projectId: this.projectId,
                    contentData: this.templateMock,
                };

                if (this.isTemplateEdit) {
                    endpoint = `/api/index.php?endpoint=enterpriseSaveTemplateEdit`;
                    formData['templateId'] = this.$route.params.templateId;
                }

                await axios
                    .post(
                        endpoint,
                        formData, // Pass form data in the request payload
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((result) => {
                        if (result.data.success) {
                            this.toast.success(`Continutul a fost actualizat!`);
                        } else {
                            this.toast.error(
                                `Continutul nu a putut fi adaugat`
                            );
                        }
                    })
                    .catch(() => {
                        this.toast.error(`Continutul nu a putut fi adaugat`);
                    });
            } catch (error) {
                console.error(
                    'Error updating template:',
                    error.response ? error.response.data : error.message
                );
            }
        },
        async getTemplateData(enterpriseTemplateId) {
            let endpoint = `/api/index.php?endpoint=getInvitationContent&projectId=${
                this.projectId
            }&isOwner=${this.isOwner}${
                this.isSimple
                    ? '&isSimple=true'
                    : '&invitationId=' + this.invitationId
            }${
                this.isTemplateEdit
                    ? '&isTemplateEdit=true&templateId=' + enterpriseTemplateId
                    : ''
            }`;

            if (this.isTemplateEdit && enterpriseTemplateId) {
                endpoint = `/api/index.php?endpoint=getEnterpriseTemplateContent&templateId=${enterpriseTemplateId}`;
            }

            const jwtToken = localStorage.getItem('userToken') || 0;
            axios
                .get(endpoint, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                })
                .then((response) => {
                    if (response && response.data.success) {
                        // Assuming projects is an array, update the index based on your API response
                        const projectData = response.data.data.projects[0];
                        this.projectId = projectData.project_id;
                        this.projectName = projectData.project_name;
                        this.projectDate = projectData.project_date;
                        this.templateId = projectData.project_template;
                        this.templateContent = projectData.invitation_content;
                        this.templateMock = JSON.parse(this.templateContent);
                        this.imagesData = projectData.project_images || [];
                        this.templateImages = projectData.template_images;
                    }

                    if (!response || !response.data || !response.data.success) {
                        this.$router.push('/');
                    }
                })
                .catch(() => {
                    this.$router.push('/');
                });
        },
        updateSection(sectionKey, updatedSection, dbSave) {
            this.templateMock[sectionKey] = updatedSection;

            if (dbSave === true) {
                this.saveTemplateJsonData();
            }
        },

        addSection(nextSectionKey, newSection) {
            if (Object.keys(this.templateMock).length > 7) {
                this.toast.error(`Sunt permise maxim 7 sectiuni !`);
            } else {
                this.templateMock[nextSectionKey] = newSection;
                this.toast.success(`Sectiunea a fost adaugata!`);
                this.reorderSections();
            }
        },

        deleteSection(sectionKey) {
            delete this.templateMock[sectionKey];
        },

        updateSectionOrder(sectionKey, order) {
            this.templateMock[sectionKey].order = order;
            this.reorderSections();
        },

        removeBackgroundImage() {
            this.templateMock[this.editedSectionKey].backgroundImage.value = '';
        },

        reorderSections() {
            const sectionsArray = Object.entries(this.templateMock);
            sectionsArray.sort((a, b) => {
                const orderA =
                    a[1]['order'] !== undefined
                        ? a[1]['order']
                        : Number.MAX_SAFE_INTEGER;
                const orderB =
                    b[1]['order'] !== undefined
                        ? b[1]['order']
                        : Number.MAX_SAFE_INTEGER;
                return orderA - orderB;
            });
            this.templateMock = Object.fromEntries(sectionsArray);
        },

        getSectionCount() {
            return Object.keys(this.templateMock).length;
        },
    },
};
</script>

<style scoped>
.transition-height {
    transition: height 0.5s ease;
    max-height: 52rem; /* Set a maximum height if needed */
}

.parallax-section {
    background-position: 50% 0px;
}
</style>
