<template data-theme="cupcake">
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
        rel="stylesheet"
    />
    <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Merriweather:ital,wght@0,300;0,700;1,300;1,700&display=swap"
        rel="stylesheet"
    />
    <HeaderComponent v-if="hideHeader == false" :key="headerKey" />

    <router-view />

    <FooterComponent v-if="hideFooter == false" />
    <CookieNotification />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import CookieNotification from '@/components/CookieNotification.vue';
import eventBus from '@/utils/EventBus';

export default {
    data() {
        return {
            headerKey: 0,
        };
    },
    components: {
        HeaderComponent,
        FooterComponent,
        CookieNotification,
    },
    beforeUnmount() {
        eventBus.off('LoginSuccessful', this.handleAuthenticationEvent);
        eventBus.off('LogoutSuccessful', this.handleAuthenticationEvent);
    },
    mounted() {
        eventBus.on('LoginSuccessful', this.handleAuthenticationEvent);
        eventBus.on('LogoutSuccessful', this.handleAuthenticationEvent);
    },
    computed: {
        // Determine whether to show Header based on the route or view
        hideHeader() {
            return (
                this.$route.name === 'PublicInvitation' ||
                this.$route.name === 'PublicInvitationName' ||
                this.$route.name === 'PublicInvitationSimple' ||
                this.$route.name === 'PublicInvitationSimpleName' ||
                this.$route.name === 'Template Edit' ||
                this.$route.name === 'Admin Credits' ||
                this.$route.name === 'Admin Customers' ||
                this.$route.name === 'Admin Projects' ||
                this.$route.name === 'Admin Settings' ||
                this.$route.name === 'Admin Dashboard' ||
                this.$route.name === 'Admin Templates'
            );
        },
        // Determine whether to show Footer based on the route or view
        hideFooter() {
            return (
                this.$route.name === 'PublicInvitation' ||
                this.$route.name === 'PublicInvitationName' ||
                this.$route.name === 'PublicInvitationSimple' ||
                this.$route.name === 'PublicInvitationSimpleName' ||
                this.$route.name === 'Template Edit' ||
                this.$route.name === 'Admin Credits' ||
                this.$route.name === 'Admin Customers' ||
                this.$route.name === 'Admin Projects' ||
                this.$route.name === 'Admin Settings' ||
                this.$route.name === 'Admin Dashboard' ||
                this.$route.name === 'Admin Templates'
            );
        },
    },
    methods: {
        handleAuthenticationEvent() {
            this.headerKey += 1;
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    a {
        font-weight: bold;
        color: #252525;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.font-merriweather {
    font-family: 'Merriweather', serif;
}
</style>
